const styles = {
    paddingX: "sm:px-16 px-6",
    paddingY: "sm:py-16 py-6",
    padding: "sm:px-16 px-6 sm:py-16 py-10",

    heroHeadText:
        "text-[#F7F3E6] lg:text-[23px] md:text-[23px] sm:text-[15px] xs:text-[15px] text-[15px] mt-2",
    heroSubText:
        "text-[#F7F3E6] lg:text-[20px] md:text-[20px] sm:text-[15px] xs:text-[15px] text-[15px]",
    heroFilterText:
        "text-[#F7F3E6] lg:text-[14px] md:text-[14px] sm:text-[14px] xs:text-[15px] text-[14px]",
    heroFilterButton:
        "text-[#5A5C20] lg:text-[14px] sm:text-[14px] xs:text-[14px] text-[14px]",
    heroFilterTextSecond:
        "text-[#5A5C20] lg:text-[14px] md:text-[14px] sm:text-[14px] xs:text-[14px] text-[14px]",
    heroFilterButtonSecond:
        "text-[#F7F3E6] lg:text-[14px] sm:text-[14px] xs:text-[14px] text-[14px]",
    secondFilterText:
        "text-[#5A5C20] lg:text-[16px] md:text-[16px] sm:text-[14px] xs:text-[15px] text-[14px]",
    secondFilterActiveText:
        "text-[#F7F3E6] lg:text-[16px] md:text-[16px] sm:text-[14px] xs:text-[15px] text-[14px]",
    regularText:
        " lg:text-[17px] md:text-[16px] sm:text-[15px] xs:text-[15px] text-[14px]",
    smText:
        "lg:text-[13px] md:text-[13px] sm:text-[13px] xs:text-[13px] text-[13px]",
    navLinkWhite:
        "text-[#F7F3E6] lg:text-[16px] sm:text-[16px] xs:text-[16px] text-[16px]",
    navLink:
        "text-[#363535] lg:text-[16px] sm:text-[16px] xs:text-[16px] text-[16px]",
    navLinkActive:
        "text-[#5A5C20] lg:text-[16px] sm:text-[16px] xs:text-[16px] text-[16px]",
    sectionTitle:
        "lg:text-[64px] md:text-[35px] sm:text-[25px] xs:text-[25px] text-[20px]",
    secondSectionTitle:
        "text-[#5A5C20] lg:text-[40px] md:text-[25px] sm:text-[25px] xs:text-[25px] text-[20px]",
    sectionSubTitle:
        "lg:text-[20px] md:text-[20px] sm:text-[15px] xs:text-[15px] text-[15px]",
    locationTitle:
        "lg:text-[20px] md:text-[20px] sm:text-[15px] xs:text-[15px] text-[15px]",
    locationSubTitle:
        "lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px] text-[15px]",
    primaryTextColor:
        "text-[#F7F3E6]",
    primaryBackColor:
        "bg-[#F7F3E6]",
    secondaryTextColor:
        "text-[#5A5C20]",
    secondaryBackColor:
        "bg-[#5A5C20]",
    thirdTextColor:
        "text-[#363535]",

};

export { styles };
