import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import React from "react";
import HomePage from './pages/Homepage';
import RootLayout from './pages/Root';
import JournalPage from './pages/JournalPage';
import AddressPage from './pages/AddressPage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import PressPage from './pages/PressPage';
import AddressDetailPage from './pages/AddressDetailPage';
import Login from './pages/Login';
import NewsletterPage from './pages/Newsletter';


/*{

  path: '/login',
  element: <Login />,
  id: 'login',
  children: [
  {
        path: true,
        id: 'home',
        element: <HomePage />,
      },
      {
        path: 'addresses',
        id: 'addresses',
        children: [
          {
            index: true,
            id: 'address',
            element: <AddressPage />,
          },
          {
            path: ':index',
            id: 'address-detail',
            element: <AddressDetailPage />,
          },
        ]
      },
      {
        path: 'journal',
        id: 'journal',
        element: <JournalPage />,
      },
      {
        path: 'contact',
        id: 'cnotact',
        element: <ContactPage />,
      },
      {
        path: 'about',
        id: 'about',
        element: <AboutPage />,
      },
      {
        path: 'press',
        id: 'press',
        element: <PressPage />,
      },
  ]
},*/

const router = createBrowserRouter([

  {
    path: '/',
    element: <RootLayout />,
    id: 'root',
    children: [
      {
        index: true,
        id: 'newsletter',
        element: <NewsletterPage />,
      },
    ]
  },
]);

function App() {
  return <RouterProvider router={router} />
}

export default App;
