import axios from 'axios';

// Fonction pour obtenir le token CSRF depuis les cookies
function getCSRFToken() {
  const csrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('csrftoken='))
    ?.split('=')[1];
  return csrfToken;
}

// Crée une instance axios
const axiosInstance = axios.create({
  //baseURL: 'https://admin.thehost.place/',
  //baseURL: 'https://preprod.thehost.place/',
  //baseURL: 'http://localhost:8000/',
  baseURL: 'https://admin.thehost.place/',
  withCredentials: true,  // Assurez-vous que les cookies sont envoyés avec les requêtes
});



// Ajoute un intercepteur pour inclure le token JWT dans les en-têtes d'autorisation
/*axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // Assurez-vous d'ajouter à nouveau le token CSRF avec chaque requête
    const csrfToken = getCSRFToken();
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);*/


axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['X-Requested-From'] = 'ReactFrontend';  // Ajouter l'en-tête personnalisé ici
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Ajoute un intercepteur pour gérer les erreurs de réponse
axiosInstance.interceptors.response.use(
  (response) => {
    // Si la réponse est réussie, la retourner
    return response;
  },
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Utiliser window.location.replace avec une URL absolue pour rediriger vers la page de login
      //window.location.replace(`${window.location.origin}/login`);
    }
    
    return Promise.reject(error);
  }
);

export default axiosInstance;
