import React, { useState } from "react";
import { logo, backNewsPC } from '../assets';
import { styles } from "../styles";
import { useTranslation } from 'react-i18next';
import axiosInstance from '../context/axiosConfig';

function NewsletterPage() {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const blacklistedDomains = ['tempmail.com', 'mailinator.com'];

    const handleSubmit = async () => {
        // Désactiver le bouton après clic
        setIsButtonDisabled(true);

        // Vérifications avancées
        if (!email || !emailRegex.test(email)) {
            setMessage(t('form.email.error.format'));
            setIsButtonDisabled(false); // Réactiver si erreur
            return;
        }

        if (email.length > 254) {
            setMessage(t('form.email.error.toolong'));
            setIsButtonDisabled(false);
            return;
        }

        const domainPart = email.split('@')[1];
        if (email.includes('..') || !domainPart || domainPart.split('.').pop().length < 2) {
            setMessage(t('form.email.error.format'));
            setIsButtonDisabled(false);
            return;
        }

        if (blacklistedDomains.includes(domainPart)) {
            setMessage(t('form.email.error.format'));
            setIsButtonDisabled(false);
            return;
        }

        try {
            const response = await axiosInstance.post('/api/newsletter/', { email });
            setMessage(t('form.email.send'));
            setEmail('');
        } catch (err) {
            console.error('Error posting email data:', err);
            setMessage(t('form.email.error'));
        } finally {
            setIsButtonDisabled(false);
        }
    };

    const handleInputChange = (e) => {
        setEmail(e.target.value);
        setIsButtonDisabled(false); // Réactiver le bouton si une modification est faite
    };

    return (
        <>
            <div className="App flex-col">
                <img src={backNewsPC} alt="back-picture" className={`h-screen absolute w-full object-cover`} />
                <div className={`h-screen flex justify-center items-center`}>
                    <div className={`${styles.padding} max-w-[700px] mx-auto relative z-0 flex flex-col justify-center`}>
                        <img className='h-[60px] sm:h-[60px] md:h-[130px] w-[700px]' src={logo} alt="logo" />
                        <h1 className={`${styles.heroHeadText} text-center w-full libre-baskerville-regular mt-2 md:mt-7`}>
                            {t('home.text.slogan.1')}<span className='libre-baskerville-regular-italic mx-1 md:mx-2'>{t('home.text.slogan.2')}</span>{t('home.text.slogan.3')}<span className='libre-baskerville-regular-italic mx-1 md:mx-2'>{t('home.text.slogan.4')}</span>
                        </h1>
                        <div className='mx-auto max-w-[250px] md:max-w-[800px] mt-20'>
                            <h2 className={`${styles.heroSubText} text-center w-full libre-baskerville-regular`}>
                                Inscrivez-vous à notre<span className='libre-baskerville-regular-italic mx-1 md:mx-2'>newsletter</span>pour</h2>
                            <h2 className={`${styles.heroSubText} text-center w-full libre-baskerville-regular md:mt-2`}>dénicher les plus beaux lieux de France et d’ailleurs</h2>
                        </div>
                        <div className='border-[1px] border-[#F7F3E6] rounded-full h-[50px] md:h-[60px] mt-20 flex items-center justify-between pl-8'>
                            <input
                                type="email"
                                placeholder={t('footer.news.placeholder')}
                                value={email}
                                onChange={handleInputChange}
                                className={`${styles.primaryTextColor} placeholder:text-[#FFFEFA] text-[#FFFEFA] libre-baskerville-regular bg-transparent outline-none w-full`}
                            />
                            <button
                                onClick={handleSubmit}
                                disabled={isButtonDisabled}
                                className={`${styles.primaryBackColor} ${styles.regularText} text-[#363535] hover:text-[#FFFEFA] libre-baskerville-regular-italic px-10 rounded-full min-h-full flex items-center justify-center hover:bg-opacity-[0.5] hover:text-[#FFFEFA] ${isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {t('footer.news.button')}
                            </button>
                        </div>
                        <div className='relative flex items-center justify-center mt-5'>
                            {message && <p className={`${styles.smText} ${styles.primaryTextColor} absolute bottom-[-20px] libre-baskerville-regular`}>{message}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewsletterPage;
